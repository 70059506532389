import axios from "~/plugins/axios";
export default {
  // --- BASKO REGISTRATION ---
  setLotteryCode(code) {
    return axios
      .get("/ebsn/api/lottery/update-code", {
        params: {
          code: code
        }
      })
      .then(response => {
        return response.data.response;
      });
  }
};
