<template>
  <div class="payment-order-info">
    <div class="d-flex align-center justify-start mb-4">
      <img
        class="mr-3"
        height="37"
        width="42"
        :src="serviceImg"
        alt="Immagine servizio"
      />
      <span class="font-weight-bold text-body-1"
        >{{ $t("navbar.serviceSelected") }} {{ serviceName }}</span
      >
    </div>
    <div class="d-flex align-center justify-start mb-4">
      <v-icon large color="primary">$location</v-icon>
      <span class="font-weight-bold text-body-1">
        {{ serviceDeliveryText }} {{ shippingAddress }}</span
      >
    </div>
    <div class="d-flex align-center justify-start mb-4">
      <v-icon large color="primary">$editcalendar</v-icon>
      <span class="font-weight-bold text-body-1">
        {{ $t("navbar.deliveryDate") }}
        <span class="text-uppercase" v-html="timeslot"></span
      ></span>
    </div>
  </div>
</template>
<script>
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "PaymentOrderInfo",
  mixins: [cartInfo],
  computed: {
    serviceDeliveryText() {
      return this.$t(
        "navbar.service." + this.getDeliveryServiceId + ".devlieryText"
      );
    }
  }
};
</script>
