<template>
  <div class="checkout-summary-card py-3 px-3 text-left">
    <v-card-title>
      {{
        isOrderDetail ? $t("cartSummary.headerOrder") : $t("cartSummary.header")
      }}
    </v-card-title>

    <v-card-text>
      <div class="d-flex justify-space-between align-top">
        <span class="cart-items-count"
          >{{ orderCart.totalItems }} {{ $t("cartSummary.products") }}</span
        >
        <span>
          {{ $n(orderCart.totalPrice, "currency") }}
        </span>
      </div>
      <div class="d-flex justify-space-between align-top mt-1">
        <span class="pr-1">{{ $t("cartSummary.totalPriceVariable") }}</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-top font-weight-bold mt-1 primary--text"
      >
        <span>{{ $t("cartSummary.saving") }}</span>
        <span>{{ $n(saved, "currency") }}</span>
      </div>

      <!-- <div class="d-flex justify-space-between align-top font-weight-bold mt-1">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span>
          {{ $n(netTotal, "currency") }}
        </span>
      </div> -->

      <div
        class="d-flex justify-space-between align-top mt-3 font-weight-bold"
        v-if="orderCart.packageTotal > 0"
      >
        <span>{{ $t("cartSummary.packageTotal") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </div>
      <!--
      <div
        class="gift-certificates-to-generate d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
        v-for="(gift, index) in orderCart.giftCertificatesToGenerate"
        :key="index"
      >
        <span class="mr-8">{{ gift.description }}</span>
        <span v-if="gift.amount > 0 || gift.total > 0">
           {{
            gift.description.indexOf("%") > -1
              ? gift.amount + "&nbsp;%"
              : $n(gift.total, "currency")
          }}
          {{
            !(gift.amount && gift.amount >= 0) && gift.total > 0
              ? $n(gift.total, "currency")
              : ""
          }}
        </span>
      </div>
    -->
      <div
        class="d-flex justify-space-between align-top"
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
      </div>

      <div
        v-if="
          !isOrderDetail &&
            orderCart.giftCertificatesToGenerate &&
            orderCart.giftCertificatesToGenerate.length
        "
      >
        <div class="font-weight-bold mt-4">
          {{ $t("cartSummary.promo") }}
        </div>
        <div
          class="d-flex justify-space-between align-top"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span class="text-body-2 text-none gift-name">
            {{ gift.name }}
          </span>
          <span v-if="gift.total">-{{ $n(gift.total, "currency") }}</span>
        </div>
      </div>
      <!-- standard gifts -->
      <!-- <div
        class="font-weight-bold mt-4"
        v-if="
          filteredStandardGiftCertificate &&
            filteredStandardGiftCertificate.length
        "
      >
        {{ $t("cartSummary.giftCertificates") }}
      </div>
      <div
        v-for="gift in filteredStandardGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center text-body-2 text-none mt-3"
      >
        <div class="d-flex justify-space-between w-100">
          <span class="gift-name">{{ gift.name }}</span>
          <span v-if="gift.total > 0">{{
            `-${$n(gift.total, "currency")}`
          }}</span>
        </div>
        <v-btn
          v-if="!isOrderDetail && isEditable"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div> -->

      <div
        class="font-weight-bold mt-4"
        v-if="
          orderCart.giftCertificates &&
            orderCart.giftCertificates.length &&
            orderCart.giftCertificates
              .map(gift => Number(gift.total))
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              ) > 0
        "
      >
        {{ $t("cartSummary.giftCertificates") }}
      </div>
      <div
        v-for="(gift, index) in orderCart.giftCertificates"
        :key="index + '_gift'"
      >
        <div
          class="gift-certificates d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
          v-if="gift.amount > 0 && !(gift.total > 0)"
        >
          <span class="mr-8">{{ gift.description }}</span>
          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            style="font-size:1rem;"
            >-{{
              gift.description.indexOf("%") > -1
                ? gift.amount + "&nbsp;%"
                : $n(gift.amount, "currency")
            }}
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCode(gift.userGiftCertificateId)"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
        <div
          class="d-flex flex-nowrap justify-space-between align-center text-body-2 w-100 mt-4"
          v-else-if="gift.total > 0"
        >
          <span class="mr-8">{{ gift.description }}</span>
          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            style="font-size:1rem;"
            >-{{ gift.total > 0 ? $n(gift.total, "currency") : "" }}
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCode(gift.userGiftCertificateId)"
                  class="remove-gift"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>

      <div
        class="d-flex justify-space-between align-top font-weight-bold mt-5"
        v-if="
          orderCart.shippingAddress.deliveryServiceId == 2 ||
            orderCart.shippingAddress.deliveryServiceId == 4 ||
            orderCart.deliveryFee > 0
        "
      >
        <span>{{ $t("cartSummary.deliveryFee") }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </div>
      <!-- <div
        v-if="discount > 0"
        class="d-flex justify-space-between align-top font-weight-bold"
      >
        <span>{{ $t("cartSummary.discount") }}</span>
        <span> -{{ $n(discount, "currency") }} </span>
      </div> -->
      <!-- <div
        class="d-flex justify-space-between align-top mt-3 font-weight-bold"
      >
        <span>{{ $t("cartSummary.orderGrossTotal") }}</span>
        <span>{{ $n(orderGrossTotal, "currency") }}</span>
      </div> -->
      <!-- refund -->
      <!-- <div
        class="font-weight-bold mt-4"
        v-if="filteredRefundGiftCertificate.length > 0"
      >
        {{ $t("cartSummary.refund") }}
      </div> -->
      <div
        v-for="gift in filteredRefundGiftCertificate"
        :key="gift.giftCertificateId"
        class="d-flex flex-row align-center text-body-2 text-none text-uppercase font-weight-bold  mt-2"
      >
        <span class="gift-name">{{ gift.name }}</span>
        <v-spacer />
        <span v-if="gift.total > 0">{{
          `-${$n(gift.total, "currency")}`
        }}</span>
        <v-btn
          v-if="!isOrderDetail && isEditable"
          tile
          icon
          class="delete"
          @click="removeGiftCode(gift.userGiftCertificateId)"
        >
          <v-icon color="promo">$delete</v-icon>
        </v-btn>
      </div>

      <div
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['BOLL'] &&
            orderCart.userPoints['BOLL'].value > 0
        "
        class="d-flex justify-space-between align-center text-body-2 mt-4"
      >
        <span class="font-weight-bold">{{ $t("checkout.summary.earn") }}</span>
        <span
          >{{ orderCart.userPoints["BOLL"].value }}&nbsp;
          {{ $t("checkout.summary.bollini") }}
        </span>
      </div>

      <div>
        <v-divider class="my-3" />
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          class="text-h4 d-flex font-weight-bold"
        >
          <span>{{ $t("cartSummary.grossTotalToPay") }}</span>
          {{ $n(orderCart.grossTotal, "currency") }}
        </v-row>
      </div>
    </v-card-text>
    <!--

  <div v-if="remaining > 0" class="subtitle-2 minimum-purchase">
    {{
      $t("checkout.minimumPurchase", [
      $n(remaining, "currency"),
      $n(minimumPurchase, "currency")
      ])
    }}
  </div>
-->
  </div>
</template>
<style lang="scss">
.checkout-summary-card {
  .delete {
    position: absolute;
    right: -2px;
  }
  .gift-name {
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .minimum-purchase {
    color: var(--v-error-base);
  }
}
</style>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import DeliveryService from "~/service/deliveryService";

export default {
  name: "CheckoutSummary",
  props: {
    orderCart: { type: Object, required: true },
    isOrderDetail: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: true },
    canDeleteGift: { type: Boolean, default: true }
  },
  data() {
    return {
      deliveryFee: null,
      minimumAmountForNextFee: 0,
      remainingAmountForNextFee: 0
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),

    netTotal() {
      if (this.orderCart.orderId && this.orderCart.orderStatusId >= 8) {
        var refundTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_REFUND_TOTAL.totalVariation;
        var discountItemTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        var discountNetTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_NET_TOTAL.totalVariation;
        return (
          this.orderCart.grossTotal +
          refundTotalDel +
          discountItemTotalDel +
          discountNetTotalDel
        );
      }

      var netTotal = this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
      var discountItemTotal = this.orderCart.orderAdjustmentSummary
        .TYPE_ITEM_TOTAL.totalVariation;
      return netTotal + discountItemTotal;
    },
    discount() {
      var discountItemTotal =
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL_PROMO
          .totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL_GIFT
          .totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_NET_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary.TYPE_SHIPPING_TOTAL
          .totalVariation;
      return discountItemTotal;
    },
    orderGrossTotal() {
      var refundTotal = this.orderCart.orderAdjustmentSummary.TYPE_REFUND_TOTAL
        .totalVariation;
      return this.orderCart.grossTotal + refundTotal;
    },

    saved() {
      return this.orderCart.totalDiscount
        ? this.orderCart.totalDiscount
        : 0 +
            //  this.orderCart.giftTotal
            this.orderCart.orderAdjustmentSummary.TYPE_ITEM_TOTAL
              .totalVariation +
            this.orderCart.orderAdjustmentSummary.TYPE_NET_TOTAL.totalVariation;
    },
    filteredGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.total > 0;
      });
    },

    filteredStandardGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId != 51;
      });
    },

    filteredRefundGiftCertificate() {
      return this.orderCart.giftCertificates.filter(gift => {
        return gift.giftCertificateId == 51;
      });
    }
  },
  methods: {
    ...mapActions({
      remove: "cart/removeGiftCode"
    }),
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    },
    async removeGiftCode(giftCodeId) {
      await this.remove(giftCodeId);
      this.$emit("removeGiftCode", giftCodeId);
    },
    async reload() {
      await this.getDeliveryFee();
      let totalPrice = this.cart.totalPrice;
      if (
        this.deliveryFee != null &&
        this.deliveryFee.deliveryFeeRows != null
      ) {
        for (
          let index = 0;
          index < this.deliveryFee.deliveryFeeRows.length;
          index++
        ) {
          const elementRow = this.deliveryFee.deliveryFeeRows[index];

          if (elementRow.range > this.cart.totalPrice + 100) {
            this.remainingAmountForNextFee = elementRow.range - totalPrice;
            if (index + 1 < this.deliveryFee.deliveryFeeRows.length) {
              this.minimumAmountForNextFee =
                0.01 + this.deliveryFee.deliveryFeeRows[index + 1].fee;
            }
            break;
          }
        }
      }
    }
  },
  async mounted() {
    this.reload();
  }
};
</script>
