<template>
  <div class="payment payment-completed">
    <category-block
      :target="category"
      :container="false"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container fluid class="d-flex flex-column scroll-container">
      <CategoryTitle :category="category" class="px-0" />
      <v-row class="mt-5">
        <v-col cols="12" md="8" order="last" order-sm="first">
          <v-card class="card-1">
            <v-card-title>
              {{ $t("payment.block.1.title") }}
            </v-card-title>
            <v-card-text>
              <PaymentOrderInfo class="mt-4" />
              <!-- <PackagesTypeList /> -->
              <PaymentInvoice
                @billingFormCompleted="billingFormCompleted"
                class="mt-10"
              />
            </v-card-text>
          </v-card>

          <GiftListSlider
            :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
            :userId="cart.user.userId"
            :warehouseId="cart.warehouse.warehouseId"
            :layout="36"
            :limit="24"
            :paginationClass="'swiper-pagination-linea-220'"
            :showArrows="!$vuetify.breakpoint.xsOnly"
            :showBullets="$vuetify.breakpoint.xsOnly"
          />
          <v-card class="mt-5 card-2">
            <v-card-title class="pb-0">
              {{ $t("payment.block.4.title") }}
            </v-card-title>
            <v-card-text>
              <PaymentTypeList
                class="mt-1"
                :cart="cart"
                mode="cart"
                :isPaymentDisabled="
                  !billingFormCompletedInternal && invoiceNeeded
                "
                :options="paymentTypeListOptions"
                :defaultPaymentType="12"
                :autoSelectPaymentType="true"
              />
              <recaptcha-disclaimer />
            </v-card-text>
          </v-card>

          <PaymentLotteryForm @deliveryServiceIds="1" @personInfoTypeId="21" />
        </v-col>
        <v-col
          cols="12"
          md="4"
          order="first"
          order-sm="last"
          v-if="cart.shippingAddress"
        >
          <v-card>
            <CheckoutSummary
              :orderCart="cart"
              :isEditable="false"
              class="d-block"
            />
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        class="mt-10"
        x-large
        outlined
        depressed
        max-width="300"
        :block="$vuetify.breakpoint.xs"
        :to="{ name: 'Checkout' }"
      >
        <span>{{ $t("payment.button.goBack") }}</span>
      </v-btn>
    </v-container>

    <category-block
      :target="category"
      :container="false"
      position="position2"
      class="category-block category-block-2"
    />
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<style lang="scss">
.payment {
  .v-card {
    margin-bottom: 12px;
    .v-card__title {
      font-weight: bold;
      word-break: break-word;
      display: block;
    }
    .step {
      background-color: $primary;
      color: $white;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      font-size: 20px;
      display: inline-block;
      text-align: center;
      margin-right: 12px;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import PaymentTypeList from "@/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import PaymentOrderInfo from "@/components/payment/PaymentOrderInfo.vue";
import PaymentLotteryForm from "@/components/payment/PaymentLotteryForm.vue";
// import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";
import GiftListSlider from "@/components/gift/GiftListSlider.vue";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import productMixins from "~/mixins/product";
import AnalyticsService from "~/service/analyticsService";

import { mapCartInfo } from "~/service/ebsn";
import { mapState } from "vuex";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";

export default {
  name: "Payment",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  data() {
    return {
      paymentTypeList: [],
      isPaymentDisabled: false,
      billingFormCompletedInternal: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        defaultPaymentType: 12,
        // mainBackgroundColor: "grey lighten-2",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      },
      lotteryEnabled: true,
      lotteryEnabledDate: "2021-02-01"
    };
  },
  components: {
    PaymentTypeList,
    CategoryTitle,
    CheckoutSummary,
    PaymentInvoice,
    PaymentOrderInfo,
    GiftListSlider,
    // PackagesTypeList,
    PaymentLotteryForm,
    RecaptchaDisclaimer
  },

  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ needInvoice: "need_invoice" }),
    invoiceNeeded() {
      return this.needInvoice === "1" ? true : false;
    }
  },
  methods: {
    enableLottery(date) {
      var minDate = new Date(date).getTime();
      if (this.cart.timeslot && this.cart.timeslot.date) {
        var deliveryDate = new Date(this.cart.timeslot.date).getTime();
        if (deliveryDate - minDate < 0) {
          this.lotteryEnabled = false;
        }
      }
    },
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    AnalyticsService.checkoutProgress(this.cart);
    this.enableLottery(this.lotteryEnabledDate);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
